<template>
     <div class="main">
         <div class="inquiry-detail">
            <div class="title">
               <h3>{{$t("talk_room.report.title_detail")}}</h3>
            </div>
            <div class="body">
                <div class="row">
                    <div class="col-12 p-3">
                        <b>管理者通知対象投稿</b>
                        <div class="card mt-2 p-2 pb-5">
                            <div class="card-title mb-0">
                                <div class="pull-right">
                                    <p class="mb-0">{{$t("talk_room.report.contributor")}} : {{item.replier.name}}</p>
                                    <small>{{$t("talk_room.report.comment")}}: {{item.talk_room_date}}</small>
                                </div>
                            </div>
                             <div class="card-text text-pre-line">
                                <p>{{item.content}}</p>
                                <a target="_blank" :href="item.attachment" v-if="item.attachment">
                                    <div v-if="isImageUrl(item.attachment)">
                                        <img class="comment-img" :src="item.attachment" />
                                    </div>
                                    <div v-else>
                                        {{ getNameFormURL(item.attachment) }}
                                    </div>
                                </a>
                            </div>
                        </div>
                       
                    </div>
                    <div class="col-12 p-2 text-pre-line">
                        管理者にこのメッセージを報告しますか？<br/>
                        なぜあなたが通知を行なったのか、管理者が理解しやすいよう、できるだけ詳しい説明を付け加えてください。
                    </div>
                     <div class="col-12 p-2 text-pre-line">
                        ご注意ください<br/>
                        ・いただいた報告に個別にお答えすることはありません、<br/>
                        ・いただいた報告に基づいて対応、処置することをお約束するものではありません。
                    </div>
                    <div class="col-12 list">
                        <FormReport
                           :item="item"
                        />
                    </div>
                </div>
            </div>
        </div>
        <MetaTags 
            title="トークールームリスト｜ResMom相談online"
            description="リセマム相談オンラインのトークルームリストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import FormReport from './components/form-report.vue';
export default {
    name: "Report",
    data() {
        return {
            item: {
                replier: {}
            }
        };
    },
    async created() {
        let id = this.$route.params.id;
        await this.getReportTalkRoom({
            id: id,
            cb: (response) => {
                let { data, meta } = response;
                if (meta && meta.code == 200) {
                    this.item = data;
                    this.id = data.id;
                }
                else {
                   this.$router.back();
                }
            }
        });
    },
    methods: {
        ...mapActions({
            getReportTalkRoom: "talkRoom/getReportTalkRoom"
        })
    },
    components: { FormReport }
}
</script>